export const Star = ({ color, filled }: { color: string; filled?: boolean }) =>
  filled ? (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 282 276"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="noun-star-4667811"
          transform="translate(0.338000, 0.990200)"
          fill={color}
        >
          <polygon
            id="Shape"
            fillRule="nonzero"
            points="197.003 70.504 171.804 19.32 140.726 0 109.648 19.32 84.449 70.504 28 78.6798 2.84217094e-14 102.2538 8.793 137.8128 49.672 177.6288 39.8673 233.8558 53.6443 267.7938 90.1563 270.4266 140.6683 243.8246 191.1803 270.4266 207.3643 274.4578 227.6923 267.7937 241.4693 233.8557 231.7818 177.6327 272.6608 137.8167 281.4538 102.2577 253.4538 78.6797"
          ></polygon>
          <rect
            id="Rectangle"
            x="158.289024"
            y="103"
            width="18"
            height="17"
          ></rect>
        </g>
      </g>
    </svg>
  ) : (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 282 276"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="noun-star-4667811"
          transform="translate(0.338000, 0.990200)"
          fill={color}
        >
          <path
            d="M197.003,70.504 L171.804,19.32 L140.726,0 L109.648,19.32 L84.449,70.504 L28,78.6798 L2.84217094e-14,102.2538 L8.793,137.8128 L49.672,177.6288 L39.8673,233.8558 L53.6443,267.7938 L90.1563,270.4266 L140.6683,243.8246 L191.1803,270.4266 L207.3643,274.4578 L227.6923,267.7937 L241.4693,233.8557 L231.7818,177.6327 L272.6608,137.8167 L281.4538,102.2577 L253.4538,78.6797 L197.003,70.504 Z M216.8859,168.6728 L214.4797,176.122 L224.8937,236.716 L217.7804,254.189 L198.9644,255.5328 L144.5894,226.9158 L140.6675,225.96268 L136.7456,226.9158 L82.3706,255.5328 L63.5546,254.189 L56.4413,236.716 L66.8553,176.122 L64.4491,168.6728 L20.3791,125.7778 L15.8439,107.4658 L30.2929,95.3138 L91.1639,86.5208 L97.492,81.927 L124.652,26.767 L140.668,16.7982 L156.684,26.767 L183.844,81.927 L190.1721,86.5208 L251.0431,95.3138 L265.4921,107.4658 L260.9569,125.7778 L216.8859,168.6728 Z"
            id="Shape"
            fillRule="nonzero"
          ></path>
          <rect
            id="Rectangle"
            x="158.289024"
            y="103"
            width="18"
            height="17"
          ></rect>
        </g>
      </g>
    </svg>
  )
